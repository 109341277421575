




























import {Prop, Vue, Watch} from 'vue-property-decorator';
import Component from 'vue-class-component';
import DatePicker from '@/components/DatePicker.vue';
import DateShortcuts from '@/components/DateShortcuts/DateShortcuts.vue';

@Component({
  name: 'SeparateDateRangePicker',
  components: {DateShortcuts, DatePicker}
})
export default class SeparateDateRangePicker extends Vue {
  @Prop()
  value!: string[]

  startDate: string = ''
  finishDate: string = ''

  @Watch('value', { immediate: true })
  onValueChange(value: string[]) {
    this.startDate = value[0]
    this.finishDate = value[1]
  }

  onShortcutSelected([startDate, finishDate]: [string, string]) {
    this.startDate = startDate
    this.finishDate = finishDate

    this.changed()
  }

  changed() {
    this.$emit('input', [this.startDate, this.finishDate])
  }
}
