




























import {Component, Vue} from 'vue-property-decorator';
import {IDateShortcut} from '@/components/DateShortcuts/types';
import {dateToYYYYmmDD, subtractDays} from '@/utils/dateTime';

@Component({ name: 'DateShortcuts' })
export default class DateShortcuts extends Vue {
  shortcuts: IDateShortcut[] = [
    {
      label: 'SYSTEM_TODAY',
      onClick: () => subtractDays(new Date(), 0, true)
    },
    {
      label: 'SYSTEM_LAST_7_DAYS',
      onClick: () => subtractDays(new Date(),7, true)
    },
    {
      label: 'SYSTEM_LAST_14_DAYS',
      onClick: () => subtractDays(new Date(),14, true)
    },
    {
      label: 'SYSTEM_LAST_30_DAYS',
      onClick: () => subtractDays(new Date(),30, true)
    }
  ]

  onShortcutClicked(shortcut: IDateShortcut) {
    this.$emit('select', [<string>shortcut.onClick(), <string>dateToYYYYmmDD(new Date())])
  }
}
