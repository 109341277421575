
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import IDashboardFilter from '@/types/IDashboardFilter';
import DateRangePicker from '@/components/DateRangePicker.vue';
import CollapseMultiSelect from '@/components/CollapseMultiSelect.vue';
import {arrayLength, required} from '@/utils/validationRules';
import {IAnalysisMarketplace} from '@/types/IAnalysisMarketplace';
import {isEqualDates} from '@/utils/dateTime';
import SeparateDateRangePicker from '@/components/SeparateDateRangePicker.vue';
import DateShortcuts from '@/components/DateShortcuts/DateShortcuts.vue';
import MultiSelect from '@/components/MultiSelect.vue';

@Component({
  components: {
    MultiSelect,
    DateShortcuts,
    SeparateDateRangePicker,
    CollapseMultiSelect,
    DateRangePicker,
  },
})
export default class DashboardAnalysisFilters extends Vue {
  @Prop()
  filters!: IDashboardFilter;

  @Prop()
  keys!: IAnalysisMarketplace[];

  @Prop()
  keysSelectLabel!: string;

  @Prop({default: false})
  collapseKeysSelect!: boolean;

  rules = {
    dateRange: [required, this.dateRangeValidator],
    keys: [required, this.arrayValidator],
  };

  get dateRange(): (string | null)[] {
    if (this.filters.dateTo) {
      return [this.filters.dateFrom, this.filters.dateTo];
    } else if (this.filters.dateFrom) {
      return [this.filters.dateFrom];
    } else {
      return [];
    }
  }

  set dateRange(dateRange: (string | null)[]) {
    if (dateRange[0] && dateRange[1] && dateRange[1] < dateRange[0]) {
      this.filters.dateFrom = dateRange[1];
      this.filters.dateTo = dateRange[0];
    } else {
      this.filters.dateFrom = dateRange[0];
      this.filters.dateTo = dateRange[1];
    }
  }

  unselectMarketplace(marketplace: IAnalysisMarketplace) {
    this.filters.keys = this.filters.keys.filter((item: any) => item !== marketplace.name)
    this.changed()
  }

  allowedDates(date: string) {
    const parsedDate = new Date(date)
    const todayDataUpdateAt = new Date().setUTCHours(14, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    if (isEqualDates(parsedDate, yesterday, true)) {
      const currentDate = new Date()
      return currentDate.getTime() > todayDataUpdateAt
    }

    if (isEqualDates(parsedDate, new Date(), true)) {
      return false
    }

    return parsedDate.getTime() < todayDataUpdateAt
  }

  dateRangeValidator(value: string) {
    return arrayLength(value ? value.split(' - ') : [], 2) === true || this.$t('SYSTEM_END_DATE_NEEDS_TO_BE_PROVIDED');
  }

  arrayValidator(value: any[]) {
    return value.length != 0 || this.$t('SYSTEM_REQUIRED_FIELD')
  }

  changed() {
    Object.keys(this.filters).forEach((key) => {
      // @ts-ignore
      this.filters[key] = this.filters[key] || '';
    });
    if (this.filters.dateFrom && this.filters.dateTo && this.filters.keys.length != 0) {
      this.$emit('changed', this.filters);
    }
  }
}
